import React from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import { ToolCard } from '../components/ToolCard';

export const UserTools = ({ userTools, connectedTools, handleConnect }) => (
  <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
    {userTools.map((tool, index) => (
      <ToolCard
        key={index}
        tool={tool}
        isConnected={connectedTools[tool]}
        onConnect={handleConnect}
      />
    ))}
  </SimpleGrid>
);
