import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = ({ className, style }) => {
    const { logout } = useAuth0();

    return (
        <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} className={className} style={style}>
            Log Out
        </button>
    );
};

export default LogoutButton;