import React, { useState, useRef } from 'react';
import { Box, VStack, HStack, Text, Grid, GridItem, Flex, Heading, Button as ChakraButton, Spinner, Center } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon, RepeatIcon } from '@chakra-ui/icons';
import { FiRepeat } from 'react-icons/fi';
import { TaskItem } from './TaskItem';

export const Calendar = ({ onTaskSelect, tasks, onRefresh, isLoading, currentDate, onDateChange }) => {
  const [activeTasks, setActiveTasks] = useState(new Set());
  const scrollContainerRef = useRef(null);

  // Remove the useEffect and generateTasksForDate function as we're now using the tasks from props

  // ... existing changeDate, formatDate, and other utility functions ...

  // Update getTasksForSlot to work with the new task structure
  const getTasksForSlot = (slot) => {
    if (!tasks) return [];
    const [slotHour, slotMinute] = slot.split(':').map(Number);
    const slotStart = new Date(currentDate);
    slotStart.setHours(slotHour, slotMinute, 0, 0);
    const slotEnd = new Date(slotStart);
    slotEnd.setMinutes(slotEnd.getMinutes() + 30);

    return tasks.filter(task => {
      const startTime = new Date(task.start_timestamp);
      const endTime = new Date(task.end_timestamp);
      return (startTime < slotEnd && endTime > slotStart);
    });
  };

  // Update TaskItem to use the new task structure
  const renderTaskItem = (task) => {
    const startTime = new Date(task.start_timestamp);
    const endTime = new Date(task.end_timestamp);
    const duration = `${startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - ${endTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;

    return (
      <TaskItem
        key={task.id}
        time={startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
        tool={task.tool}
        purpose={task.description}
        duration={duration}
        isActive={activeTasks.has(task.id)}
        onClick={() => task.loop_name ? null : toggleTaskActive(task.id)}
        isDisabled={!!task.loop_name}
        loopName={task.loop_name}
        loopIcon={task.loop_name ? <FiRepeat /> : null}
      />
    );
  };

  const changeDate = (days) => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + days);
    if (newDate <= new Date(new Date().setHours(23, 59, 59, 999))) {
      onDateChange(newDate);
      setActiveTasks(new Set());
      onTaskSelect([]);
    }
  };

  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
  };

  const toggleTaskActive = (taskId) => {
    setActiveTasks(prev => {
      const newSet = new Set(prev);
      if (newSet.has(taskId)) {
        newSet.delete(taskId);
      } else {
        newSet.add(taskId);
      }
      const selectedTasks = tasks.filter(task => newSet.has(task.id));
      onTaskSelect(selectedTasks);
      return newSet;
    });
  };

  const weekDays = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const getWeekDates = () => {
    const currentDay = currentDate.getDay();
    const diff = currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1);
    const monday = new Date(currentDate);
    monday.setDate(diff);
    return weekDays.map((day, index) => {
      const date = new Date(monday);
      date.setDate(monday.getDate() + index);
      return {
        date,
        day,
        isCurrentDay: date.toDateString() === currentDate.toDateString(),
        isPastDay: date < today,
        isToday: date.toDateString() === today.toDateString()
      };
    });
  };

  const handleDateClick = (date) => {
    if (date <= new Date(new Date().setHours(23, 59, 59, 999))) {
      onDateChange(date);
      setActiveTasks(new Set());
      onTaskSelect([]);
    }
  };

  // Generate time slots for a 24-hour period with 30-minute intervals
  const timeSlots = Array.from({ length: 48 }, (_, i) => {
    const hour = Math.floor((i + 12) / 2) % 24; // Start the day at 6:00 AM
    const minute = i % 2 === 0 ? '00' : '30';
    return `${hour.toString().padStart(2, '0')}:${minute}`;
  });

  return (
    <Box width="100%" height="100vh" p={8}>
      <VStack spacing={4} align="stretch" height="100%">
        {/* Header with task manager title and refresh button */}
        <Box pb={4} borderBottom="1px solid" borderColor="gray.200">
          <HStack justify="space-between">
            <Heading size="lg" mb={6} color="black">
              Task Manager
            </Heading>
            <ChakraButton leftIcon={<RepeatIcon />} colorScheme="gray" variant="outline" size="sm" onClick={onRefresh}>
              Refresh
            </ChakraButton>
          </HStack>
        </Box>

        {/* Calendar header with date, navigation, and weekdays */}
        <Flex justify="space-between" align="center" py={4} borderBottom="1px solid" borderColor="gray.200">
          <Text fontSize="lg" color="gray.600">{formatDate(currentDate)}</Text>
          <HStack spacing={1}>
            <ChakraButton size="sm" variant="ghost" onClick={() => changeDate(-1)}><ChevronLeftIcon /></ChakraButton>
            {getWeekDates().map(({ date, day, isCurrentDay, isPastDay, isToday }) => (
              <VStack
                key={day}
                bg={isCurrentDay ? "green.500" : "transparent"}
                color={isCurrentDay ? "white" : isPastDay || isToday ? "gray.500" : "gray.300"}
                borderRadius="md"
                w={12}
                h={12}
                justify="center"
                spacing={0}
                cursor={(isPastDay || isToday) ? "pointer" : "default"}
                onClick={() => handleDateClick(date)}
              >
                <Text fontSize="xs" fontWeight="bold">{day}</Text>
                <Text fontSize="sm" fontWeight="bold">{date.getDate()}</Text>
              </VStack>
            ))}
            <ChakraButton size="sm" variant="ghost" onClick={() => changeDate(1)} isDisabled={currentDate >= today}><ChevronRightIcon /></ChakraButton>
          </HStack>
        </Flex>

        {/* Scrollable container for tasks */}
        <Box overflowY="auto" ref={scrollContainerRef} flex={1}>
          {isLoading ? (
            <Center height="100%">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Center>
          ) : (
            <Grid templateColumns="50px 1fr" gap={4}>
              {timeSlots.map((time) => {
                const tasksForSlot = getTasksForSlot(time);
                return (
                  <React.Fragment key={time}>
                    <GridItem>
                      <Text fontSize="sm" color="gray.500">{time}</Text>
                    </GridItem>
                    <GridItem>
                      <Box borderTop="1px solid" borderColor="gray.200" minHeight="31px">
                        <VStack spacing={0} align="stretch" py={1}>
                          {tasksForSlot.map(task => renderTaskItem(task))}
                        </VStack>
                      </Box>
                    </GridItem>
                  </React.Fragment>
                );
              })}
            </Grid>
          )}
        </Box>
      </VStack>
    </Box>
  );
};