
// Your React component where you want to use the HTML content
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios'; // Add this import

// import css


import logoImage from './images/logo.svg';


const DownloadApp = () => {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const navbarHeight = '120px';
  const toggleMenu = () => {
    setIsMenuExpanded(current => !current);
  };


  useEffect(() => {
    const handleOutsideClick = (event) => {
      const navbar = document.querySelector('.navbar4_component');
      if (isMenuExpanded && navbar && !navbar.contains(event.target)) {
        setIsMenuExpanded(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isMenuExpanded]);

  useEffect(() => {
    const handleMenuAnimation = () => {
      const menuIcon = document.querySelector('.menu-icon4');
      const topLine = document.querySelector('.menu-icon4_line-top');
      const middleLine = document.querySelector('.menu-icon4_line-middle');
      const bottomLine = document.querySelector('.menu-icon4_line-bottom');

      if (isMenuExpanded) {
        menuIcon.classList.add('open');
        topLine.style.transform = 'rotate(45deg) translate(6px, 6px)';
        middleLine.style.opacity = '0';
        bottomLine.style.transform = 'rotate(-45deg) translate(6px, -6px)';
      } else {
        menuIcon.classList.remove('open');
        topLine.style.transform = 'rotate(0) translate(0, 0)';
        middleLine.style.opacity = '1';
        bottomLine.style.transform = 'rotate(0) translate(0, 0)';
      }
    };

    handleMenuAnimation();
  }, [isMenuExpanded]);


  const submitDemoRequest = async (e) => {

  }

  return (
    <>
      <div className="page-wrapper">
        <div
          data-animation="default"
          className="navbar4_component w-nav"
          data-easing2="ease"
          fs-scrolldisable-element="smart-nav"
          data-easing="ease"
          data-collapse="all"
          data-w-id="e13ab862-2b2a-b56e-232c-9e2d2f0da996"
          role="banner"
          data-duration={400}
          data-doc-height={1}
        >
          <div className="navbar4_container">
            <a
              href="/"
              aria-current="page"
              className="navbar4_logo-link w-nav-brand w--current"
            >
              <img
                src={logoImage}
                loading="lazy"
                alt=""
                className="navbar4_logo"
              />
            </a>
            <div className="navbar4_wrapper">

              <div className="navbar-item">
                <a className="button is-navbar-button desktop w-button" href='/'>
                  Return Home
                </a>
              </div>

              <div className="navbar4_menu-button w-nav-button" onClick={toggleMenu}>
                <div className="menu-icon4">
                  <div className="menu-icon4_wrapper">
                    <div className="menu-icon4_line-top" />
                    <div className="menu-icon4_line-middle">
                      <div className="menu-icon_line-middle-top" />
                      <div className="menu-icon_line-middle-base" />
                    </div>
                    <div className="menu-icon4_line-bottom" />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="styles---glowing-button w-embed">

        </div>
        <div className="global-styles w-embed">

        </div>
        <main className="main-wrapper">
          <section className="section_hero">
            <div className="padding-global">
              <div className="container-large">
                <div className="hero_component">
                  <div className="hero_content">

                    <h1
                      data-w-id="301326fa-a9f6-d3f0-1b47-9b6b4c42ac69"
                      style={{ opacity: 100 }}
                    >
                      Download the
                      <span className="text-color-primary"> Caddi app</span>

                    </h1>
                    <p
                      data-w-id="6233d2b3-6ab8-4946-1015-4655c118456a"
                      style={{ opacity: 100 }}
                      className="hero_para"
                    >
                      Get the best Caddi experience by downloading the Caddi app for Mac.
                    </p>

                  </div>
                  <div
                    data-w-id="bd87e84f-d41e-8853-5454-50243a8d17eb"
                    style={{ opacity: 100 }}
                    className="hero_media-wrap"
                  >

                    

                  </div>
                </div>
              </div>
            </div>
          </section>

        </main>
      </div>
    </>

  );
};


export default DownloadApp;