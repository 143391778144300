import React from 'react';
import { Flex, Heading, HStack, Text, Button } from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';

export const LoopScreenHeader = ({ onRefresh }) => (
  <Flex justify="space-between" align="center" mb={6}>
    <Heading size="lg" color="black">Loops</Heading>
    <HStack>
      {/* <Text>Show</Text> */}
      {/* <Button variant="outline">All Loops</Button>
      <Button variant="outline" aria-label="Filter">Filter</Button> */}
      <Button leftIcon={<RepeatIcon />} onClick={onRefresh}>Refresh</Button>
    </HStack>
  </Flex>
);
