import React, { useState } from 'react';
import { Box, Flex, Text, Icon, Collapse, Image, IconButton, HStack, Center } from '@chakra-ui/react';
import { FiChevronDown, FiChevronUp, FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { TaskIcon } from './icons';

const ScreenshotDots = ({ total, current, onClick }) => (
  <HStack spacing={2} justifyContent="center" mt={2}>
    {[...Array(total)].map((_, index) => (
      <Box
        key={index}
        w={2}
        h={2}
        borderRadius="full"
        bg={index === current ? "blue.500" : "gray.300"}
        cursor="pointer"
        onClick={() => onClick(index)}
      />
    ))}
  </HStack>
);

export const LoopStep = ({ task, index }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentScreenshot, setCurrentScreenshot] = useState(0);

  const handleToggle = () => setIsExpanded(!isExpanded);

  const handleScreenshotChange = (newIndex) => {
    setCurrentScreenshot(newIndex);
  };

  const handleNextScreenshot = () => {
    setCurrentScreenshot((prev) => (prev + 1) % (task.screenshots?.length || 1));
  };

  const handlePrevScreenshot = () => {
    setCurrentScreenshot((prev) => (prev - 1 + (task.screenshots?.length || 1)) % (task.screenshots?.length || 1));
  };

  return (
    <Box borderWidth={1} borderRadius="md" p={4}>
      <Flex alignItems="center" mb={2} cursor="pointer" onClick={handleToggle}>
        <Box
          borderRadius="full"
          bg="green.500"
          color="white"
          w={8}
          h={8}
          display="flex"
          alignItems="center"
          justifyContent="center"
          mr={4}
        >
          {index + 1}
        </Box>
        <TaskIcon name={task.tool} boxSize={6} mr={4} />
        <Text fontWeight="" flex={1}>{task.description}</Text>
        <Icon
          as={isExpanded ? FiChevronUp : FiChevronDown}
          boxSize={6}
        />
      </Flex>
      <Collapse in={isExpanded} animateOpacity>
        <Box ml={12} mt={4}>
          {task.screenshots && task.screenshots.length > 0 && (
            <Box position="relative">
              <Center>
                <Image
                  src={`${task.screenshots[currentScreenshot]}`}
                  alt={`Task ${index + 1} screenshot ${currentScreenshot + 1}`}
                  border="1px solid #ccc"
                  borderRadius="md"
                  w="100%"
                  objectFit="contain"
                  minW="500px"
                  maxW="700px"
                  p={4}
                  bg="white"
                  boxShadow="md"
                  rounded="md"
                  mb={4}
                />
              </Center>
              <ScreenshotDots
                total={task.screenshots.length}
                current={currentScreenshot}
                onClick={handleScreenshotChange}
              />
            </Box>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};
