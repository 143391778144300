import React from 'react';
import { Box, Flex, Text, Button, useColorModeValue } from '@chakra-ui/react';

export const ToolCard = ({ tool, isConnected, onConnect }) => {
  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Box
      bg={bgColor}
      p={4}
      borderRadius="lg"
      boxShadow="sm"
      border="1px"
      borderColor={borderColor}
    >
      <Flex align="center" justify="space-between">
        <Text fontWeight="bold" fontSize="sm">{tool}</Text>
        <Button
          size="sm"
          onClick={() => onConnect(tool)}
          colorScheme="green"
        >
          {isConnected ? "Connected" : "Connect"}
        </Button>
      </Flex>
    </Box>
  );
};
