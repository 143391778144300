import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Heading, HStack, Button, useToast } from '@chakra-ui/react';
import { FiArrowLeft } from 'react-icons/fi';
import { TaskIcon } from '../components/icons';
import ApprovalModal from './ApprovalModal';
import { useAuth0 } from "@auth0/auth0-react";

export const LoopHeader = ({ loop, onBack, onLoopUpdate }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const toast = useToast();
  const navigate = useNavigate();

  const getLoopStatus = () => {
    if (!loop.approved) return { status: 'Draft', action: "Approve", color: 'green' };
    if (!loop.built) return { status: 'Building', action: "Building", color: 'blue' };
    return loop.enabled
      ? { status: 'Deployed', action: "Pause", color: 'green' }
      : { status: 'Paused', action: "Enable", color: 'red' };
  };

  const [loopStatus, setLoopStatus] = useState(getLoopStatus());

  useEffect(() => {
    setLoopStatus(getLoopStatus());
  }, [loop]);

  const handleApprove = async () => {
    if (loopStatus.action !== "Approve") {
      setIsModalOpen(true);
      return;
    }

    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      const response = await fetch(`${process.env.REACT_APP_API_HOSTNAME}/loops/${loop.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ approved: true }),
      });

      if (!response.ok) {
        throw new Error('Failed to approve loop');
      }

      const updatedLoop = await response.json();
      onLoopUpdate(updatedLoop);
      setLoopStatus(getLoopStatus());
      toast({
        title: "Loop Approved",
        description: "The loop has been successfully approved.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Open the modal after successful approval
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error approving loop:', error);
      toast({
        title: "Error",
        description: "Failed to approve the loop. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleGoToLoops = () => {
    navigate('/loops');
    setIsModalOpen(false);
  };

  return (
    <Flex direction="column" mb={6}>
      <Button leftIcon={<FiArrowLeft />} variant="ghost" alignSelf="flex-start" onClick={onBack} mb={4}>
        Back to All Loops
      </Button>
      <Flex justifyContent="space-between" alignItems="center">
        <HStack>
          <TaskIcon name={loop.iconName} boxSize={6} color="blue.500" />
          <Heading size="lg" color="black">{loop.name}</Heading>
        </HStack>
        <HStack>
          <Button
            size="md"
            colorScheme={loopStatus.color}
            onClick={handleApprove}
            isLoading={isLoading}
            isDisabled={loopStatus.action === "Building"}
          >
            {loopStatus.action}
          </Button>
        </HStack>
      </Flex>
      <ApprovalModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onGoToLoops={handleGoToLoops}
      />
    </Flex>
  );
};