// Import necessary dependencies
import React, { useState, useEffect } from 'react';
import { Box, Flex, Heading, SimpleGrid } from '@chakra-ui/react';
import { SidebarContent } from '../components/SidebarContent';
import { StatCard } from '../components/StatCard';
import { LoopAnalytics } from '../components/LoopAnalytics';
import { TopActiveLoops } from '../components/TopActiveLoops';
import { generateData } from '../utils/generateData';

const Dashboard = () => {
  // Default settings for the dashboard
  const defaultDateRange = 7; // Default time range for analytics data (7 days)
  const defaultLoopData = generateData(defaultDateRange); // Generate default data based on the date range
  const defaultTotalTriggers = defaultLoopData.reduce((sum, item) => sum + item.value, 0); // Calculate total triggers from default data

  const defaultMonthlySavings = "15 hours"; // Default value for monthly time savings
  const defaultPotentialSavings = "50 hours"; // Default value for potential time savings

  // State variables to manage the data displayed on the dashboard
  const [dateRange, setDateRange] = useState(defaultDateRange); // State for the selected date range
  const [loopData, setLoopData] = useState(defaultLoopData); // State for the loop data based on the date range
  const [totalTriggers, setTotalTriggers] = useState(defaultTotalTriggers); // State for the total triggers
  const [monthlySavings, setMonthlySavings] = useState(localStorage.getItem('monthlySavings') || defaultMonthlySavings); // State for the monthly time savings
  const [potentialSavings, setPotentialSavings] = useState(localStorage.getItem('potentialSavings') || defaultPotentialSavings); // State for the potential time savings

  // useEffect to load data from localStorage when the component mounts
  useEffect(() => {
    const storedDateRange = Number(localStorage.getItem('dateRange')) || defaultDateRange; // Load date range from localStorage
    const storedLoopData = JSON.parse(localStorage.getItem('loopData')) || generateData(storedDateRange); // Load loop data from localStorage or generate new data
    const storedTotalTriggers = storedLoopData.reduce((sum, item) => sum + item.value, 0); // Calculate total triggers

    setDateRange(storedDateRange);
    setLoopData(storedLoopData);
    setTotalTriggers(storedTotalTriggers);
  }, []);

  // useEffect to update the loop data and save the current state to localStorage whenever the date range or savings values change
  useEffect(() => {
    const newData = generateData(dateRange); // Generate new loop data based on the selected date range
    setLoopData(newData);
    setTotalTriggers(newData.reduce((sum, item) => sum + item.value, 0)); // Update the total triggers

    // Save the current state to localStorage
    localStorage.setItem('dateRange', dateRange);
    localStorage.setItem('loopData', JSON.stringify(newData));
    localStorage.setItem('monthlySavings', monthlySavings);
    localStorage.setItem('potentialSavings', potentialSavings);
  }, [dateRange, monthlySavings, potentialSavings]);

  return (
    <Flex>
      {/* Sidebar component */}
      <SidebarContent display={{ base: 'none', md: 'block' }} />
      
      {/* Main content area */}
      <Box ml={{ base: 0, md: 60 }} p="4" flex={1}>
        <Box p={8}>
          {/* Dashboard heading */}
          <Heading size="lg" mb={6} color="black">
            Overview
          </Heading>

          {/* Display statistics cards for monthly and potential time savings */}
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6} mb={8}>
            <StatCard title="Monthly Time Savings" value={monthlySavings} />
            <StatCard title="Potential Time Savings" value={potentialSavings} />
          </SimpleGrid>

          {/* Display loop analytics section */}
          <LoopAnalytics 
            dateRange={dateRange} 
            setDateRange={setDateRange} 
            loopData={loopData} 
            totalTriggers={totalTriggers} 
          />

          {/* Display top active loops */}
          <TopActiveLoops />
        </Box>
      </Box>
    </Flex>
  );
};

export default Dashboard;
