// TODO: Add all supported tools here

export const supportedTools = [
  "Wix",
  "Squarespace",
  "FinancialAdvisoryWebsites.com",
  "TwentyOverTen",
  "FMG Suite",
  "Advisor Websites",
  "RIA in a Box",
  "Google Ads",
  "Facebook Ads",
  "Bing Ads",
  "SmartAsset",
  "Nerd Wallet",
  "Paladin Registry",
  "Advisor Websites Direct",
  "Redtail CRM",
  "Wealthbox CRM",
  "Salesforce Financial Services Cloud",
  "Junxure",
  "Ebix SmartOffice",
  "Hubly",
  "Wealthsite",
  "Clio Grow",
  "Lawmatics",
  "Advyzon",
  "Zoe Financial",
  "FeeX",
  "Positivly",
  "Snappy Kraken",
  "Dripify",
  "Flodesk",
  "Gmail",
  "Microsoft Outlook",
  "Constant Contact",
  "Mailchimp",
  "ActiveCampaign",
  "Redtail Speak",
  "Calendly",
  "Acuity Scheduling",
  "ScheduleOnce",
  "Timetrade",
  "SchedulingPlus",
  "Doodle",
  "Zoom",
  "GoToMeeting",
  "RingCentral",
  "BlueJeans",
  "Cisco Webex",
  "Microsoft Teams",
  "Otter.ai",
  "Rev",
  "Evernote",
  "Microsoft OneNote",
  "Notability",
  "Roam Research",
  "Salesforce Experience Cloud",
  "Advisor Stream",
  "InvestCloud",
  "Orion",
  "Black Diamond",
  "Read.AI",
  "eMoney",
  "MoneyGuidePro",
  "RightCapital",
  "NaviPlan",
  "MoneyTree",
  "Advizr",
  "Envestnet",
  "First Step",
  "Tamarac",
  "Addepar",
  "Morningstar Office",
  "AdvisorPeak",
  "Riskalyze",
  "RiXtrema",
  "HiddenLevers",
  "RiskPro",
  "FinaMetrica",
  "Tolerisk",
  "RiXreveal",
  "ByAllAccounts",
  "Quovo",
  "Yodlee",
  "Wealth Access",
  "Captools",
  "Aqumulate",
  "ShareFile",
  "Box",
  "DocuPhase",
  "NetDocuments",
  "Laserfiche",
  "SmartVault",
  "XY Archive",
  "PreciseFP",
  "FINRA Tools",
  "ComplySci",
  "Patrina",
  "Smarsh",
  "Orion Compliance",
  "RegEd",
  "Albridge",
  "Vestmark",
  "Kwanti",
  "Clearnomics",
  "AdvicePay",
  "BillFin",
  "FinancePath",
  "YCharts",
  "Sentieo",
  "Senseney Analytics",
  "Zacks Investment Research",
  "FactSet",
  "S&P Global",
  "Refinitiv",
  "WealthFeed",
  "S&P Capital IQ",
  "Bloomberg",
  "Zephyr",
  "Style Analytics",
  "FinMobile",
  "Palico",
  "CAIS",
  "iCapital Network",
  "Artivest",
  "FNEX",
  "Alts.com",
  "CAIS Alternative Investment Platform",
  "Pershing Managed Protection",
  "RIA WorkSpace",
  "PortfolioProtector",
  "SysCloud",
  "RIA Cybersecurity Solutions",
  "Kaplan",
  "CFFP",
  "CFP Board",
  "Dalbar",
  "A4A",
  "IMCA",
  "Investopedia Academy",
  "Wealthscape",
  "Appway",
  "Skience",
  "iRebal",
  "TradeWarrior",
  "Orion Communities",
  "Black Diamond BluePrint",
  "Holistiplan",
  "Lacerte",
  "ProTracker Tax",
  "QuickBooks",
  "Xero",
  "FreshBooks",
  "WealthCounsel",
  "Stratwealth",
  "Dropbox",
  "Google Drive",
  "OneDrive",
  "DocuSign",
  "Adobe Sign",
  "RightSignature",
  "HelloSign",
  "PandaDoc",
  "Slack",
  "Flock",
  "Twist",
  "Chanty",
  "Discord",
  "Mattermost",
  "Trello",
  "Asana",
  "Monday.com",
  "Wrike",
  "Smartsheet",
  "Gusto",
  "OnPay",
  "Paychex",
  "ADP",
  "Zenefits",
  "Toggl",
  "Harvest",
  "RescueTime",
  "ClickTime",
  "Timely",
  "Zapier",
  "Rewind AI",
  "Guideline",
  "WordPress",
  "LawLytics",
  "Justia",
  "Firm-Sites",
  "AttorneySync",
  "LexisNexis Web Visibility Solutions",
  "Avvo",
  "LegalZoom",
  "Martindale",
  "Lawyers.com",
  "FindLaw",
  "Nolo",
  "Yelp for Business",
  "Rocket Lawyer",
  "PracticePanther",
  "Lexicata",
  "Lawcus",
  "HubSpot CRM",
  "Salesforce Essentials",
  "Pipedrive",
  "LawDroid",
  "Legal.io",
  "IntakeAssistant",
  "Intake123",
  "Captorra",
  "Juvo Intake",
  "Hootsuite",
  "Buffer",
  "Sprout Social",
  "Later",
  "Sendible",
  "Clio Inbox",
  "ProtonMail",
  "Zoho Mail",
  "Clio Scheduler",
  "Setmore",
  "TimeTap",
  "YouCanBook.me",
  "RingCentral Meetings",
  "Google Meet",
  "Webex",
  "Trint",
  "Descript",
  "Sonix",
  "Notion",
  "Dialpad",
  "Vonage",
  "Nextiva",
  "Grasshopper",
  "Ooma",
  "Jive",
  "Ruby Receptionists",
  "Smith.ai",
  "Answering Legal",
  "Fancy Hands",
  "PATLive",
  "LexReception",
  "Back Office Betties",
  "Firm Central",
  "ActionStep",
  "CaseFleet",
  "CASEpeer",
  "LibreOffice Writer",
  "Scrivener",
  "Grammarly",
  "Hemingway Editor",
  "ProWritingAid",
  "iCloud",
  "Egnyte",
  "pCloud",
  "Fantastical",
  "Apple Calendar",
  "Any.do Calendar",
  "SignNow",
  "eversign",
  "Flock",
  "Twist",
  "Chanty",
  "Discord",
  "Mattermost",
  "LastPass",
  "1Password",
  "Dashlane",
  "Keeper",
  "Bitwarden",
  "Basecamp",
  "ClickUp",
  "Clio Manage",
  "Smokeball",
  "Filevine",
  "Rocket Matter",
  "Litify",
  "Ironclad",
  "Concord",
  "ContractWorks",
  "Apttus",
  "Icertis",
  "Conga CLM",
  "Agiloft",
  "Juro",
  "Evisort",
  "Westlaw",
  "LexisNexis",
  "Fastcase",
  "Casetext",
  "Ravel Law",
  "Ross Intelligence",
  "Judicata",
  "LegalSifter",
  "Kirasystems",
  "Luminance",
  "Kira Systems",
  "Leverton",
  "eBrevia",
  "LawGeex",
  "Spellbook",
  "Robin AI",
  "Loio",
  "Harvey",
  "HotDocs",
  "ContractExpress",
  "Documate",
  "Contract Logix",
  "XpressDox",
  "Woodpecker",
  "Lawyaw",
  "Gavel.io",
  "Intapp Conflicts",
  "Litify Conflicts",
  "Dye & Durham Conflicts",
  "Serengeti Tracker",
  "Prosperoware",
  "Clio Conflicts Check",
  "Cosmolex Conflict Checker",
  "Relativity",
  "Exterro",
  "Logikcull",
  "Everlaw",
  "Nextpoint",
  "Disco",
  "Nuix",
  "TrialPad",
  "Sanction",
  "LIT SOFTWARE",
  "CaseMap",
  "Summation",
  "TrialDirector",
  "TranscriptPad",
  "PatSnap",
  "Innography",
  "Anaqua (IP)",
  "Aderant (Legal Accounting)",
  "Klarity (Contract Review)",
  "TurboPatent (IP)",
  "Alt Legal (IP)",
  "Westlaw Drafting Assistant",
  "LexisNexis for Microsoft Office",
  "PerfectIt",
  "WordRake",
  "BriefCatch",
  "PCLaw",
  "Sage 50cloud",
  "Wave Accounting",
  "TimeSolv",
  "Zola Suite",
  "Tabs3",
  "Bill4Time",
  "CosmoLex",
  "LawPay",
  "Timekept",
  "TSheets",
  "Time Doctor",
  "Lawline",
  "Lawvision",
  "Practising Law Institute",
  "Lawdible",
  "Lawclerk",
  "Quimbee",
  "LawPavilion",
  "Hotshot",
  "BambooHR",
  "Namely",
  "LegalSpark",
  "Logicforce",
  "Mitratech",
  "Ipro",
  "Netwrix",
  "Proofpoint",
  "Doxly",
  "SkySlope",
  "Qualia",
  "Closing.com",
  "Adeptcloud",
  "Dealroom",
  "Litera Transact",
  "Gavelytics",
  "Lex Machina",
  "Premonition",
  "Allegory",
  "LegalMation",
  "Intapp Flow",
  "Onit",
  "IFTTT",
  "Tonkean",
  "DocuSign Notary",
  "NotaryCam",
  "SIGNiX",
  "Notarize",
  "PandaDoc Notary",
  "Notarize.com",
  "eNotaryLog",
  "SurveyMonkey",
  "Typeform",
  "Google Forms",
  "ClientPulse",
  "AskNicely",
  "Docket Alarm",
  "Trellis",
  "UniCourt",
  "Judicial Analytics",
  "Gavelytics",
  "ROSS Intelligence",
  "Neota Logic",
  "Josef",
  "DoNotPay",
  "Priori Legal",
  "Lexoo",
  "Lawdingo",
  "Legal Pigeon",
  "Elevate",
  "Integreon",
  "UnitedLex",
  "QuisLex",
  "Axiom"
];


