import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  InputGroup,
  InputRightElement,
  IconButton,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

export const ConnectToolModal = ({
  isOpen,
  onClose,
  currentTool,
  email,
  setEmail,
  password,
  setPassword,
  apiToken,
  setApiToken,
  submitCredentials,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showToken, setShowToken] = useState(false);

  const handleTogglePassword = () => setShowPassword(!showPassword);
  const handleToggleToken = () => setShowToken(!showToken);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Connect to {currentTool}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>API Token</FormLabel>
              <InputGroup>
                <Input 
                  type={showToken ? "text" : "password"}
                  value={apiToken} 
                  onChange={(e) => setApiToken(e.target.value)} 
                  placeholder="Enter your API token"
                />
                <InputRightElement>
                  <IconButton
                    aria-label={showToken ? "Hide token" : "Show token"}
                    icon={showToken ? <ViewOffIcon /> : <ViewIcon />}
                    onClick={handleToggleToken}
                    variant="ghost"
                    size="sm"
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel>Email/Username</FormLabel>
              <Input 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                placeholder="Enter your email or username"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input 
                  type={showPassword ? "text" : "password"}
                  value={password} 
                  onChange={(e) => setPassword(e.target.value)} 
                  placeholder="Enter your password"
                />
                <InputRightElement>
                  <IconButton
                    aria-label={showPassword ? "Hide password" : "Show password"}
                    icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                    onClick={handleTogglePassword}
                    variant="ghost"
                    size="sm"
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="green" mr={3} onClick={submitCredentials}>
            Connect
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};