import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { Auth0ProviderWithNavigate } from "./components/Auth0ProviderWithNavigate";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ChakraProvider>
        <BrowserRouter>
            <Auth0ProviderWithNavigate>
                <App />
            </Auth0ProviderWithNavigate>
        </BrowserRouter>
    </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Mixpanel Project Token
// mixpanel.init("d5dbab7671c1368b9c8c29f3839aeb85", {
//   debug: true,
//   track_pageview: true,
//   persistence: "localStorage",
// });

// TODO: Add proxy to avoid losing tracking information from adblockers (https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#tracking-via-proxy) (https://github.com/mixpanel/tracking-proxy)
// const MIXPANEL_CUSTOM_LIB_URL = "https://<YOUR_PROXY_DOMAIN>/lib.min.js";
// mixpanel.init("<YOUR_PROJECT_TOKEN>", {api_host: "https://<YOUR_PROXY_DOMAIN>"})
