import React from 'react';
import { Flex, InputGroup, InputLeftElement, Input, Button } from '@chakra-ui/react';
import { SearchIcon, AddIcon } from '@chakra-ui/icons';

export const ToolSearchBar = ({ searchTerm, setSearchTerm, handleSearch, addCustomTool }) => (
  <Flex mb={6}>
    <InputGroup mr={4} flex={1}>
      <InputLeftElement pointerEvents="none">
        <SearchIcon color="gray.300" />
      </InputLeftElement>
      <Input
        placeholder="Search apps"
        value={searchTerm}
        onChange={handleSearch}
      />
    </InputGroup>
    <Button
      colorScheme="green"
      leftIcon={<AddIcon />}
      onClick={addCustomTool}
    >
      Add custom
    </Button>
  </Flex>
);
