import React from 'react';
import { Icon } from "@chakra-ui/react";

export const AIStarIcon = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      d="M12,1L9,9L1,12L9,15L12,23L15,15L23,12L15,9L12,1Z"
    />
  </Icon>
);