import React, { useState } from 'react';
import { VStack, HStack, Box, Text, Icon, Collapse, Image, IconButton, Button as ChakraButton } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon, CheckIcon } from '@chakra-ui/icons';
import { FiRepeat } from 'react-icons/fi';
import { TaskButton } from './TaskButton';
import { TaskIcon } from './icons';

export const TaskItem = ({ time, tool, purpose, duration, isActive, onClick, screenshots, isDisabled, loopName, loopIcon }) => {
  const [showScreenshots, setShowScreenshots] = useState(false);
  const [currentScreenshot, setCurrentScreenshot] = useState(0);

  const toggleScreenshots = (e) => {
    e.stopPropagation();
    setShowScreenshots(!showScreenshots);
  };

  const nextScreenshot = (e) => {
    e.stopPropagation();
    setCurrentScreenshot((prev) => (prev + 1) % screenshots.length);
  };

  const prevScreenshot = (e) => {
    e.stopPropagation();
    setCurrentScreenshot((prev) => (prev - 1 + screenshots.length) % screenshots.length);
  };

  return (
    <VStack align="stretch" width="100%">
      <TaskButton
        isActive={isActive}
        onClick={isDisabled ? null : onClick}
        justifyContent="flex-start"
        px={4}
        py={3}
        mb={2}
        opacity={isDisabled ? 0.7 : 1}
        cursor={isDisabled ? "default" : "pointer"}
      >
        <HStack spacing={4} align="center" w="100%">
          <TaskIcon name={tool} boxSize={6} color="gray.500" />
          <Box flex={1} textAlign="left">
            <Text fontWeight="bold">{tool}</Text>
            <Text fontSize="xs" color="gray.600">{purpose} | {duration}</Text>
          </Box>

          {loopName && (
            <HStack spacing={1}>
              <Icon as={FiRepeat} color="gray.500" />
              <Text fontSize="xs" color="gray.500">{loopName}</Text>
            </HStack>
          )}

          {isActive && !isDisabled && <Icon as={CheckIcon} color="green.500" boxSize={6} />}
        </HStack>
      </TaskButton>

      {screenshots && screenshots.length > 0 && (
        <>
          <ChakraButton size="sm" onClick={toggleScreenshots} mt={2}>
            {showScreenshots ? 'Hide Screenshots' : 'Show Screenshots'}
          </ChakraButton>
          <Collapse in={showScreenshots}>
            <Box position="relative" mt={2}>
              <Image src={screenshots[currentScreenshot]} alt={`Screenshot ${currentScreenshot + 1}`} />
              <HStack position="absolute" bottom="0" left="0" right="0" justify="center" p={2} bg="rgba(0,0,0,0.5)">
                <IconButton
                  icon={<ChevronLeftIcon />}
                  onClick={prevScreenshot}
                  aria-label="Previous screenshot"
                  size="sm"
                />
                <Text color="white">{`${currentScreenshot + 1} / ${screenshots.length}`}</Text>
                <IconButton
                  icon={<ChevronRightIcon />}
                  onClick={nextScreenshot}
                  aria-label="Next screenshot"
                  size="sm"
                />
              </HStack>
            </Box>
          </Collapse>
        </>
      )}
    </VStack>
  );
};
