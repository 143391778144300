import React from 'react';
import { Button as ChakraButton, Box } from '@chakra-ui/react';

export const TaskButton = ({ children, isActive, onClick, ...props }) => (
  <ChakraButton
    width="100%"
    height="62px"
    flexShrink={0}
    borderRadius="7px"
    border="1px solid"
    borderColor={isActive ? "transparent" : "rgba(238, 238, 238, 1)"}
    bg="white"
    boxShadow={isActive ? "0px 5px 5px 0px rgba(0, 0, 0, 0.15)" : "0px 2px 5px 0px rgba(0, 0, 0, 0.05)"}
    _hover={{ bg: "gray.50" }}
    onClick={onClick}
    position="relative"
    overflow="hidden"
    {...props}
  >
    {children}
    {isActive && (
      <Box
        position="absolute"
        right="0"
        top="0"
        bottom="0"
        width="14%"
        bg="linear-gradient(90deg, rgba(65, 199, 131, 0) 0%, rgba(65, 199, 131, 0.25) 100%)"
      />
    )}
  </ChakraButton>
);
