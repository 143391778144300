import React from 'react';
import { Box, Button, Icon } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { AIStarIcon } from '../components/AIStarIcon';

export const EmptyState = () => (
  <Box textAlign="center" mt={20}>
    <Button
      as={RouterLink}
      to="/task-manager"
      colorScheme="green"
      leftIcon={<Icon as={AIStarIcon} />}
      size="lg"
    >
      Create your first loop
    </Button>
  </Box>
);
