import React, { useState } from "react";
import mixpanel from "mixpanel-browser";
import { useNavigate } from "react-router-dom";
import { useColorMode, useColorModeValue } from "@chakra-ui/color-mode";
import { Box, Flex, Button, VStack, Link, useColorModeValue as bg } from "@chakra-ui/react";
import { Stack, Heading, Text, Link as ChakraLink } from "@chakra-ui/react";
import { SidebarContent } from "../components/SidebarContent";
import { Drawer, DrawerContent } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';
import { Image } from "@chakra-ui/react";


const LoadingScreen = (props) => {
  // const navigate = useNavigate();
  // const { isOpen, onOpen, onClose } = useDisclosure();

  // TODO: Replace delay with actual loading time
  // setTimeout(() => {
  //   navigate("/dashboard");
  // }, 5000);



  return (
    <>
      {/* <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
        <SidebarContent onClose={onClose} display={{ base: 'none', md: 'block' }} />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>

        <Flex
          minH={'100vh'}
          align={'center'}
          justify={'center'}
          bg={'white'}>
          <Stack spacing={8} mx={'auto'} py={12} px={0}>
            <Stack align={'center'}>
              <Image src={'../images/loadingTools.png'} alt="Logo" width = {'600px'}/>
              <Heading fontSize={'3xl'}>Finding the right automations for your firm...</Heading>
              <Text fontSize={'lg'} color={'gray.600'}>
              Our AI analyzes 100s of tools and picks the right fit for you  
              </Text>
            </Stack>
          </Stack>
        </Flex>

      </Box> */}


    </>
  );
};

export default LoadingScreen;
