import React, { useEffect, useState } from 'react';
import { Box, Flex, Heading, Text, useToast, VStack } from '@chakra-ui/react';
import { SidebarContent } from '../components/SidebarContent';
import { ToolSearchBar } from '../components/ToolSearchBar';
import { ToolSearchResults } from '../components/ToolSearchResults';
import { UserTools } from '../components/UserTools';
import { ReviewTools } from '../components/ReviewTools';
import { ConnectToolModal } from '../components/ConnectToolModal';
import { supportedTools } from '../components/supportedTools';

export default function Credentials() {
  const [userTools, setUserTools] = useState([]);
  const [connectedTools, setConnectedTools] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [reviewTools, setReviewTools] = useState([]);
  const [apiToken, setApiToken] = useState('');
  const toast = useToast();

  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);
  const [currentTool, setCurrentTool] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const storedTools = JSON.parse(localStorage.getItem('firmTools') || '[]');
    setUserTools(storedTools);
    const storedReviewTools = JSON.parse(localStorage.getItem('reviewTools') || '[]');
    setReviewTools(storedReviewTools);
    const storedConnectedTools = JSON.parse(localStorage.getItem('connectedTools') || '{}');
    setConnectedTools(storedConnectedTools);
    const storedApiToken = localStorage.getItem('apiToken') || '';
    setApiToken(storedApiToken);
  }, []);

  const handleConnect = (tool) => {
    setCurrentTool(tool);
    setIsConnectModalOpen(true);
  };

  const submitCredentials = async () => {
    try {
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrl = apiHostname
        ? `https://${apiHostname}/api/toolCredentials`
        : "http://localhost:5001/api/toolCredentials";

      const user_id = localStorage.getItem('user_id');
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiToken}`
        },
        body: JSON.stringify({
          user_id: user_id,
          tool: currentTool,
          email: email,
          password: password,
        }) 
      });

      if (response.ok) {
        const updatedConnectedTools = {
          ...connectedTools,
          [currentTool]: true
        };
        setConnectedTools(updatedConnectedTools);
        localStorage.setItem('connectedTools', JSON.stringify(updatedConnectedTools));
        localStorage.setItem('apiToken', apiToken);

        toast({
          title: "Connected successfully",
          description: `You are now connected to ${currentTool}.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        setIsConnectModalOpen(false);
        setEmail('');
        setPassword('');
      } else {
        toast({
          title: "Connection failed",
          description: "There was an error connecting to the tool. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error storing credentials: ", error);
      toast({
        title: "Connection failed",
        description: "There was an error connecting to the tool. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    if (term) {
      const results = supportedTools.filter(tool => 
        tool.toLowerCase().includes(term.toLowerCase())
      ).slice(0, 5);
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  const addTool = (tool) => {
    if (!userTools.includes(tool)) {
      const newUserTools = [...userTools, tool];
      setUserTools(newUserTools);
      localStorage.setItem('firmTools', JSON.stringify(newUserTools));
      toast({
        title: "Tool added",
        description: `${tool} has been added to your tools.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setSearchTerm('');
      setSearchResults([]);
    }
  };

  const addCustomTool = () => {
    if (searchTerm && !supportedTools.includes(searchTerm)) {
      const newReviewTools = [...reviewTools, searchTerm];
      setReviewTools(newReviewTools);
      localStorage.setItem('reviewTools', JSON.stringify(newReviewTools));
      toast({
        title: "Custom tool submitted",
        description: `${searchTerm} has been submitted for review.`,
        status: "info",
        duration: 3000,
        isClosable: true,
      });
      setSearchTerm('');
      setSearchResults([]);
    }
  };

  return (
    <Flex>
      <SidebarContent display={{ base: 'none', md: 'block' }} />
      <Box ml={{ base: 0, md: 60 }} p="4" flex={1}>
        <Box p={8}>
          <VStack spacing={8} align="stretch">
            <Heading size="lg" color="black">Connect to your apps</Heading>

            <Box>
              <Heading size="md" mb={4}>Search for tools</Heading>
              <ToolSearchBar
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                handleSearch={handleSearch}
                addCustomTool={addCustomTool}
              />

              {searchResults.length > 0 && (
                <ToolSearchResults
                  searchResults={searchResults}
                  addTool={addTool}
                />
              )}

              {searchTerm && searchResults.length === 0 && (
                <Text mt={2}>No tools found. You can add it as a custom tool for review.</Text>
              )}
            </Box>

            <Box>
              <Heading size="md" mb={4}>Your Tools</Heading>
              <UserTools
                userTools={userTools}
                connectedTools={connectedTools}
                handleConnect={handleConnect}
              />
            </Box>

            {reviewTools.length > 0 && (
              <Box>
                <Heading size="md" mb={4}>Tools Under Review</Heading>
                <ReviewTools reviewTools={reviewTools} />
              </Box>
            )}
          </VStack>
        </Box>

        <ConnectToolModal
          isOpen={isConnectModalOpen}
          onClose={() => setIsConnectModalOpen(false)}
          currentTool={currentTool}
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          apiToken={apiToken}
          setApiToken={setApiToken}
          submitCredentials={submitCredentials}
        />
      </Box>
    </Flex>
  );
}