import React, { useState, useEffect, useCallback } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { Box, Flex, Spinner, Center, useToast } from '@chakra-ui/react';
import { SidebarContent } from '../components/SidebarContent';
import { LoopScreenHeader } from '../components/LoopScreenHeader';
import { LoopSection } from '../components/LoopSection';
import { EmptyState } from '../components/EmptyState';
import DetailedLoopView from './DetailedLoopView';
import { useAuth0 } from "@auth0/auth0-react";

const LoopScreen = () => {
  const [loops, setLoops] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const toast = useToast();
  const { getAccessTokenSilently } = useAuth0();

  const fetchLoops = useCallback(async () => {
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently({
        authorizationParams: {
          scope: 'read:loops',
        }
      });
      const response = await fetch(`${process.env.REACT_APP_API_HOSTNAME}/loops`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to fetch loops');
      }
      const fetchedLoops = await response.json();

      // Fetch tasks for each loop
      const loopsWithTasks = await Promise.all(fetchedLoops.map(async (loop) => {
        const tasksResponse = await fetch(`${process.env.REACT_APP_API_HOSTNAME}/loops/${loop.id}/tasks`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (tasksResponse.ok) {
          const tasks = await tasksResponse.json();
          return { ...loop, tasks };
        }
        return loop;
      }));

      setLoops(loopsWithTasks);
    } catch (e) {
      console.error(e);
      toast({
        title: "Error",
        description: e.message || "Failed to fetch loops",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }, [getAccessTokenSilently, toast]);

  useEffect(() => {
    fetchLoops();
  }, [fetchLoops]);

  const handleLoopClick = (loop) => {
    navigate(`/loops/${loop.id}`, { state: { loop } });
  };

  const drafts = loops.filter(loop => !loop.approved);
  const active = loops.filter(loop => loop.approved);

  return (
    <Flex>
      <SidebarContent display={{ base: 'none', md: 'block' }} />
      <Box ml={{ base: 0, md: 60 }} p="4" flex={1}>
        <Routes>
          <Route
            path="/"
            element={
              <Box p={8}>
                <LoopScreenHeader onRefresh={fetchLoops} />
                {isLoading ? (
                  <Center height="100%">
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="blue.500"
                      size="xl"
                    />
                  </Center>
                ) : loops.length === 0 ? (
                  <EmptyState />
                ) : (
                  <>
                    <LoopSection title="Drafts" loops={drafts} onLoopClick={handleLoopClick} />
                    <LoopSection title="Active" loops={active} onLoopClick={handleLoopClick} />
                  </>
                )}
              </Box>
            }
          />
          <Route
            path="/:loopId"
            element={<DetailedLoopView />}
          />
        </Routes>
      </Box>
    </Flex>
  );
};

export default LoopScreen;
