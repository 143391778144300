import React, { useState, useEffect, useCallback } from 'react';
import { Box, Flex, Drawer, DrawerContent, useToast } from '@chakra-ui/react';
import { SidebarContent } from '../components/SidebarContent';
import { Calendar } from '../components/Calendar';
import { CreateLoops } from '../components/CreateLoops';
import { LoopBuilder } from '../components/LoopBuilder';
import { useAuth0 } from '@auth0/auth0-react';

/**
 * TaskManager Component
 * The main component that renders the task management interface,
 * including the calendar view, loop builder, and sidebar navigation.
 */
export const TaskManager = () => {
  const [isOpen, setIsOpen] = useState(false); // State for controlling sidebar drawer visibility
  const [selectedTasks, setSelectedTasks] = useState([]); // State to store the currently selected tasks
  const [tasks, setTasks] = useState(null);
  const [isLoading, setIsLoading] = useState(true);  // New loading state
  const [currentDate, setCurrentDate] = useState(new Date());
  const toast = useToast();

  const { getAccessTokenSilently } = useAuth0();

  const fetchTasks = useCallback(async (date) => {
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently({
        authorizationParams: {
          scope: 'read:tasks',
        }
      });
      // Adjust the date to account for timezone differences
      const adjustedDate = new Date(date);
      adjustedDate.setMinutes(adjustedDate.getMinutes() - adjustedDate.getTimezoneOffset());
      const formattedDate = adjustedDate.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
      const response = await fetch(`${process.env.REACT_APP_API_HOSTNAME}/tasks?date=${formattedDate}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to fetch tasks');
      }
      const fetchedTasks = await response.json();
      setTasks(fetchedTasks);
    } catch (e) {
      console.error(e);
      toast({
        title: "Error",
        description: e.message || "Failed to fetch tasks",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setTasks([]);
    } finally {
      setIsLoading(false);
    }
  }, [getAccessTokenSilently, toast]);

  useEffect(() => {
    fetchTasks(currentDate);
  }, [fetchTasks, currentDate]);

  // Function to close the sidebar drawer
  const onClose = () => setIsOpen(false);

  // Function to open the sidebar drawer
  const onOpen = () => setIsOpen(true);

  // Function to handle task selection
  const handleTaskSelect = (selectedTasks) => {
    setSelectedTasks(selectedTasks);
    // If you still want to store in localStorage, you can do:
    // localStorage.setItem('selectedTasks', JSON.stringify(selectedTasks));
  };

  // Effect to load selected tasks from local storage when the component mounts
  useEffect(() => {
    const storedSelectedTasks = JSON.parse(localStorage.getItem('selectedTasks')) || [];
    setSelectedTasks(storedSelectedTasks);
  }, []);

  const handleDateChange = (newDate) => {
    // Ensure the date is set to midnight in the local timezone
    const localMidnight = new Date(newDate.setHours(0, 0, 0, 0));
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (localMidnight > today) {
      toast({
        title: "Invalid Date",
        description: "Cannot select future dates",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setCurrentDate(localMidnight);
  };

  const handleRefresh = () => {
    fetchTasks(currentDate);
  };

  return (
    <Box minH="100vh">
      {/* Sidebar content, visible on larger screens */}
      <SidebarContent onClose={onClose} display={{ base: 'none', md: 'block' }} />

      {/* Sidebar drawer for smaller screens */}
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>

      {/* Main content area */}
      <Box ml={{ base: 0, md: '240px' }} p={4} height="100vh" overflow="hidden">
        <Flex height="100%">
          {/* Calendar view area */}
          <Box
            flex="2"
            bg="white"
            height="100%"
            position="relative"
          >
            <Box
              position="absolute"
              top={0}
              right={0}
              bottom={0}
              width="1px"
              bg="gray.200"
            />
            <Box mx={8}>
              <Calendar
                onTaskSelect={handleTaskSelect}
                tasks={tasks}
                onRefresh={handleRefresh}
                isLoading={isLoading}
                currentDate={currentDate}
                onDateChange={handleDateChange}
              />
            </Box>
          </Box>

          {/* Loop builder or loop creation prompt */}
          <Box
            flex="1"
            width="453px"
            height="100%"
            flexShrink={0}
            bg="white"
            boxShadow="-4px 0px 5px 0px rgba(0, 0, 0, 0.05)"
            overflowY="auto"
            p={4}
          >
            {selectedTasks.length > 0 ? (
              <LoopBuilder selectedTasks={selectedTasks} />
            ) : (
              <CreateLoops />
            )}
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default TaskManager;
