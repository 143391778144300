import React from 'react';
import { Box, Flex, Text, Button, Heading, SimpleGrid, useColorModeValue } from '@chakra-ui/react';

export const ReviewTools = ({ reviewTools }) => {
  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Box mt={8}>
      <Heading size="md" mb={4} color="black">Tools Requested for Review</Heading>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
        {reviewTools.map((tool, index) => (
          <Box
            key={index}
            bg={bgColor}
            p={4}
            borderRadius="lg"
            boxShadow="sm"
            border="1px"
            borderColor={borderColor}
          >
            <Flex align="center" justify="space-between">
              <Text fontWeight="bold" fontSize="sm">{tool}</Text>
              <Button size="sm" colorScheme="gray" isDisabled>
                Under Review
              </Button>
            </Flex>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};
