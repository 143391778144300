import React from 'react';
import { HStack, Button } from '@chakra-ui/react';

export const LoopTags = ({ tags }) => (
  <HStack spacing={2} mb={8}>
    {tags.map((tag, index) => (
      <Button key={index} size="sm" variant="outline">
        {tag}
      </Button>
    ))}
  </HStack>
);