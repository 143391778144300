import React from 'react';
import { Box, VStack, HStack, Text, Input, Button as ChakraButton } from '@chakra-ui/react';
import { TaskIcon } from './icons';
import { TaskButton } from './TaskButton';  // Assuming you have this component

export const LoopBuilder = ({ selectedTasks }) => (
  <Box p={4}>
    <VStack spacing={6} align="stretch">
      <HStack justify="space-between">
        <Text fontSize="2xl" fontWeight="bold">Loop Builder</Text>
        <ChakraButton colorScheme="green">Create Loop</ChakraButton>
      </HStack>
      <Box>
        <Text mb={2} fontWeight="semibold">Name</Text>
        <Input placeholder="Untitled 1" />
      </Box>
      <Box>
        <Text mb={2} fontWeight="semibold">Selected Time Cards</Text>
        <VStack align="stretch" spacing={3}>
          {selectedTasks.map((task, index) => (
            <HStack key={index} align="center" spacing={4}>
              <Box 
                bg="green.100" 
                color="black"
                borderRadius="full" 
                w={8}
                h={8}
                display="flex"
                alignItems="center"
                justifyContent="center"
                fontWeight="bold"
              >
                {index + 1}
              </Box>
              <TaskButton
                isActive={false}
                onClick={() => {}}
                justifyContent="flex-start"
                px={4}
                py={3}
                flex={1}
              >
                <HStack spacing={4} align="center" w="100%">
                  <TaskIcon name={task.tool} boxSize={6} color="gray.500" />
                  <Box flex={1} textAlign="left">
                    <Text fontWeight="bold">{task.tool}</Text>
                    <Text fontSize="xs" color="gray.600">{task.purpose} </Text>
                  </Box>
                </HStack>
              </TaskButton>
            </HStack>
          ))}
        </VStack>
      </Box>
    </VStack>
  </Box>
);