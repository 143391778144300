import React from 'react';
import { Box, Text, useColorModeValue } from '@chakra-ui/react';

export const StatCard = ({ title, value }) => (
  <Box
    bg={useColorModeValue('white', 'gray.700')}
    p={5}
    borderRadius="lg"
    boxShadow="sm"
    border="1px"
    borderColor={useColorModeValue('gray.200', 'gray.600')}
  >
    <Text fontSize="sm" color="gray.500">
      {title}
    </Text>
    <Text fontSize="3xl" fontWeight="bold">
      {value}
    </Text>
  </Box>
);
