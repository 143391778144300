import React from 'react';
import { Box, Text, Button, useColorModeValue } from '@chakra-ui/react';
import { LineChart, Line, ResponsiveContainer } from 'recharts';
import { generateData } from '../utils/generateData';

export const LoopCard = ({ title, triggers }) => (
  <Box
    bg={useColorModeValue('white', 'gray.700')}
    p={4}
    borderRadius="lg"
    boxShadow="sm"
    border="1px"
    borderColor={useColorModeValue('gray.200', 'gray.600')}
  >
    <Text fontSize="sm" noOfLines={2}>
      {title}
    </Text>
    <Text fontSize="xl" fontWeight="bold" mt={2}>
      {triggers} triggers
    </Text>
    <ResponsiveContainer width="100%" height={50}>
      <LineChart data={generateData(7)}>
        <Line type="monotone" dataKey="value" stroke="#48BB78" strokeWidth={2} dot={false} />
      </LineChart>
    </ResponsiveContainer>
    <Button size="sm" colorScheme="green" mt={2}>
      View
    </Button>
  </Box>
);
