import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, VStack, Spinner, Center, useToast, Heading, Text, Flex } from '@chakra-ui/react';
import { SidebarContent } from '../components/SidebarContent';
import { LoopHeader } from '../components/LoopHeader';
import { LoopDetails } from '../components/LoopDetails';
import { LoopStep } from '../components/LoopStep';
import { useAuth0 } from "@auth0/auth0-react";
import { LoopTags } from '../components/LoopTags';
const DetailedLoopView = () => {
  const { loopId } = useParams();
  const [loop, setLoop] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const toast = useToast();
  const { getAccessTokenSilently } = useAuth0();

  const fetchLoopDetails = useCallback(async () => {
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently({
        authorizationParams: {
          scope: 'read:loops',
        }
      });
      const [loopResponse, tasksResponse] = await Promise.all([
        fetch(`${process.env.REACT_APP_API_HOSTNAME}/loops/${loopId}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        fetch(`${process.env.REACT_APP_API_HOSTNAME}/loops/${loopId}/tasks`, {
          headers: { Authorization: `Bearer ${token}` },
        })
      ]);

      if (!loopResponse.ok || !tasksResponse.ok) {
        throw new Error('Failed to fetch loop details or tasks');
      }

      const [fetchedLoop, fetchedTasks] = await Promise.all([
        loopResponse.json(),
        tasksResponse.json()
      ]);

      setLoop(fetchedLoop);
      setTasks(fetchedTasks);
      console.log(fetchedLoop);
      console.log(fetchedTasks);
    } catch (e) {
      console.error(e);
      toast({
        title: "Error",
        description: e.message || "Failed to fetch loop details",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }, [getAccessTokenSilently, loopId, toast]);

  useEffect(() => {
    fetchLoopDetails();
  }, [fetchLoopDetails]);

  const handleBack = () => {
    navigate('/loops');
  };

  const handleLoopUpdate = (updatedLoop) => {
    setLoop(updatedLoop);
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <Center height="100vh">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Center>
      );
    }

    if (!loop) {
      return <Box>Loop not found</Box>;
    }

    return (
      <Box p={8}>
        <VStack spacing={0} align="stretch">
          <LoopHeader loop={loop} onBack={handleBack} onLoopUpdate={handleLoopUpdate} />
          <LoopDetails loop={{ tasks: tasks, last_modified: loop.last_modified }} />
          <LoopTags tags={tasks.map(task => task.tool)} />
          <Text fontSize="lg" fontWeight="">Tasks</Text>
        </VStack>
        <VStack spacing={6} align="stretch">
          {tasks.length > 0 ? (
            tasks.map((task, index) => (
              <LoopStep
                key={task.id}
                task={task}
                index={index}
              />
            ))
          ) : (
            <Text>No tasks found for this loop.</Text>
          )}
        </VStack>
      </Box>
    );
  };

  return (
    <Flex>
      <SidebarContent display={{ base: 'none', md: 'block' }} />
      <Box ml={{ base: 0, md: 60 }} p="4" flex={1}>
        {renderContent()}
      </Box>
    </Flex>
  );
};

export default DetailedLoopView;