import React from 'react';
import { Box, Heading, SimpleGrid } from '@chakra-ui/react';
import { LoopCard } from '../components/LoopCard';

export const TopActiveLoops = () => (
  <Box>
    <Heading size="md" mb={4} color="black">
      Top Active Loops
    </Heading>
    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
      <LoopCard title="Create followup email for client invoice" triggers="XX" />
      <LoopCard title="Create followup email for client invoice" triggers="XX" />
      <LoopCard title="Create followup email for client invoice" triggers="XX" />
    </SimpleGrid>
  </Box>
);
