import React, { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  VStack,
  Icon,
  Link,
  Image,
  Avatar,
  useColorModeValue,
  Button,
  Divider,
} from '@chakra-ui/react';
import {
  FiHome,
  FiCalendar,
  FiRepeat,
  FiKey,
  FiHelpCircle,
  FiSettings,
  FiLogOut,
} from 'react-icons/fi';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

const LinkItems = [
  { name: 'Dashboard', icon: FiHome, path: '/dashboard' },
  { name: 'Task Manager', icon: FiCalendar, path: '/task-manager' },
  { name: 'Loops', icon: FiRepeat, path: '/loops' },
  { name: 'Credentials', icon: FiKey, path: '/credentials' },
  { name: 'Help & Support', icon: FiHelpCircle, path: '/help', isDisabled: true },
  { name: 'Settings', icon: FiSettings, path: '/settings', isDisabled: true },
];

const SidebarContent = ({ ...rest }) => {
  const { user, logout } = useAuth0();
  const { name, picture, email } = user;
  const navigate = useNavigate();
  const location = useLocation();
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const handleSignOut = () => {
    // Implement your sign out logic here
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <Box
      bg={bgColor}
      borderRight="1px"
      borderRightColor={borderColor}
      w={{ base: 'full', md: '240px' }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Image src="/images/CaddiLogoAndText.png" alt="Caddi" h="8" />
      </Flex>
      <VStack align="stretch" spacing={0}>
        {LinkItems.map((link) => (
          <NavItem
            key={link.name}
            icon={link.icon}
            path={link.path}
            isActive={location.pathname === link.path}
            isDisabled={link.isDisabled}
          >
            {link.name}
          </NavItem>
        ))}
      </VStack>
      <Box position="absolute" bottom="0" left="0" right="0" p="4">
        <Divider mb="4" />
        <Flex alignItems="center" mb="4">
          <Avatar size="sm" src="/path-to-avatar.jpg" mr="3" />
          <Box flex="1">
            <Text fontWeight="medium" fontSize="sm">{name}</Text>
            <Text fontSize="xs" color="gray.500">{email}</Text>
          </Box>
        </Flex>
        <Button
          leftIcon={<FiLogOut />}
          onClick={handleSignOut}
          variant="outline"
          size="sm"
          width="full"
        >
          Logout
        </Button>
      </Box>
    </Box>
  );
};

const NavItem = ({ icon, children, path, isActive, isDisabled, ...rest }) => {
  const navigate = useNavigate();
  const activeColor = useColorModeValue('green.500', 'green.200');
  const hoverBg = useColorModeValue('green.50', 'green.700');
  const disabledColor = useColorModeValue('gray.300', 'gray.600');

  return (
    <Link
      onClick={() => !isDisabled && navigate(path)}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
      pointerEvents={isDisabled ? 'none' : 'auto'}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        bg={isActive ? 'green.100' : 'transparent'}
        color={isDisabled ? disabledColor : (isActive ? activeColor : 'inherit')}
        _hover={{
          bg: isDisabled ? 'transparent' : hoverBg,
          color: isDisabled ? disabledColor : activeColor,
        }}
        boxShadow={isActive ? '0 0 0 2px green.500' : 'none'}
        transition="all 0.3s"
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            as={icon}
            color={isDisabled ? disabledColor : (isActive ? activeColor : 'gray.500')}
            _groupHover={{
              color: isDisabled ? disabledColor : activeColor,
            }}
          />
        )}
        <Text fontSize="sm" fontWeight={isActive ? 'bold' : 'normal'}>
          {children}
        </Text>
      </Flex>
    </Link>
  );
};

export { SidebarContent, NavItem };