import React from 'react';
import { Text } from '@chakra-ui/react';

const calculateTimeDifference = (lastModified) => {
  const now = new Date();
  const modifiedDate = new Date(lastModified);
  const diffInMilliseconds = now - modifiedDate;
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInDays > 0) {
    return `${diffInDays} day${diffInDays > 1 ? 's' : ''}`;
  } else if (diffInHours > 0) {
    return `${diffInHours} hour${diffInHours > 1 ? 's' : ''}`;
  } else {
    return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''}`;
  }
};

export const LoopDetails = ({ loop }) => (
  <Text fontSize="sm" color="gray.500" mb={6}>
    {loop.tasks.length} actions • Edited {calculateTimeDifference(loop.last_modified)} ago
  </Text>
);