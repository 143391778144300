// icons.js
import React from 'react';
import { Icon } from '@chakra-ui/react';
import { 
  MdEmail, 
  MdVideoCall, 
  MdWeb, 
  MdCode, 
  MdDescription, 
  MdChat,
  MdCloud,
  MdFolder,
  MdSettings,
  MdAssignment,
  MdDateRange
} from 'react-icons/md';
import { 
  FaSlack, 
  FaGithub, 
  FaJira, 
  FaFigma,
  FaTrello,
  FaDropbox,
  FaWordpress,
  FaAws,
  FaDocker,
  FaNpm
} from 'react-icons/fa';
import {
  SiMicrosoftteams,
  SiMicrosoftword,
  SiMicrosoftexcel,
  SiMicrosoftpowerpoint,
  SiGoogledocs,
  SiGooglesheets,
  SiGooglecalendar,
  SiAdobephotoshop,
  SiAdobeillustrator,
  SiVisualstudiocode,
  SiPostman,
  SiNotion,
  SiEvernote,
  SiAsana,
  SiZoom
} from 'react-icons/si';

export const iconMap = {
  // Communication & Collaboration
  Gmail: MdEmail,
  Outlook: MdEmail,
  Zoom: MdVideoCall,
  'Microsoft Teams': SiMicrosoftteams,
  Slack: FaSlack,
  Discord: MdChat,
  
  // Productivity & Project Management
  Asana: SiAsana,
  Trello: FaTrello,
  Jira: FaJira,
  'Microsoft Word': SiMicrosoftword,
  'Microsoft Excel': SiMicrosoftexcel,
  'Microsoft PowerPoint': SiMicrosoftpowerpoint,
  'Google Docs': SiGoogledocs,
  'Google Sheets': SiGooglesheets,
  'Google Calendar': SiGooglecalendar,
  Notion: SiNotion,
  Evernote: SiEvernote,
  
  // Design & Creative
  Figma: FaFigma,
  'Adobe Photoshop': SiAdobephotoshop,
  'Adobe Illustrator': SiAdobeillustrator,
  
  // Development & Coding
  'Visual Studio Code': SiVisualstudiocode,
  GitHub: FaGithub,
  Postman: SiPostman,
  
  // Cloud & Storage
  Dropbox: FaDropbox,
  'Google Drive': MdCloud,
  
  // Web Browsers & Internet
  Chrome: MdWeb,
  Firefox: MdWeb,
  Safari: MdWeb,
  
  // CMS & E-commerce
  WordPress: FaWordpress,
  
  // DevOps & Infrastructure
  AWS: FaAws,
  Docker: FaDocker,
  
  // Version Control & Package Management
  Git: FaGithub,
  npm: FaNpm,
  
  // Miscellaneous
  Calendar: MdDateRange,
  'Time Tracker': MdDateRange,
  'Code Editor': MdCode,
  'Document': MdDescription,
  'Project': MdAssignment,
  'Settings': MdSettings,
  'Folder': MdFolder
};

export const TaskIcon = ({ name, ...props }) => {
  const IconComponent = iconMap[name] || MdWeb; // Default to web icon if not found
  return <Icon as={IconComponent} {...props} />;
};