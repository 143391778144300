export const generateData = (days) => {
    const data = [];
    const now = new Date();
    now.setHours(0, 0, 0, 0);
  
    if (days <= 7) {
      for (let i = days - 1; i >= 0; i--) {
        const date = new Date(now.getTime() - i * 24 * 60 * 60 * 1000);
        data.push({
          name: date.toLocaleDateString('en-US', { weekday: 'short' }),
          value: Math.floor(Math.random() * 100) + 20,
          fullDate: date,
        });
      }
    } else {
      const weeks = Math.ceil(days / 7);
      for (let i = weeks - 1; i >= 0; i--) {
        const date = new Date(now.getTime() - i * 7 * 24 * 60 * 60 * 1000);
        data.push({
          name: `Week ${weeks - i}`,
          value: Math.floor(Math.random() * 700) + 140,
          fullDate: date,
        });
      }
    }
  
    return data;
  };
  