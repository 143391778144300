import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';

const ApprovalModal = ({ isOpen, onClose, onGoToLoops }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">Looped in!</ModalHeader>
        <ModalBody>
          <VStack spacing={4}>
            <Image src="/images/CaddiLogo.png" alt="Caddi Logo" boxSize="100px" />
            <Text textAlign="center">
              We are working on fully automating this workflow. We will notify you when it's ready!
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button variant="ghost" onClick={onClose} mr={3}>
            Dismiss
          </Button>
          <Button colorScheme="green" onClick={onGoToLoops}>
            Go to Loops
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ApprovalModal;