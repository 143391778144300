import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import { LoopItem } from './LoopItem';

export const LoopSection = ({ title, loops, onLoopClick }) => (
  <Box mb={8}>
    <Heading size="md" mb={4} color="black">
      {title}
    </Heading>
    {loops.map((loop) => (
      <LoopItem
        key={loop.id}
        loop={loop}
        onClick={() => onLoopClick(loop)}
      />
    ))}
  </Box>
);