import React from 'react';
import { Box, Flex, Text, HStack, Button, Icon } from '@chakra-ui/react';
import { FiRepeat, FiCheckCircle, FiEdit3, FiPause, FiLoader } from 'react-icons/fi';

export const LoopItem = ({ loop, onClick }) => {
  const tools = loop.tasks ? [...new Set(loop.tasks.map(task => task.tool))].slice(0, 3) : [];

  return (
    <Box
      borderWidth={1}
      borderRadius="md"
      p={4}
      mb={4}
      cursor="pointer"
      _hover={{ bg: 'gray.50' }}
      onClick={onClick}
    >
      <Flex align="center" justify="space-between">
        <Flex align="center" flex={1}>
          <Icon as={FiRepeat} boxSize={6} mr={4} />
          <Box>
            <Text fontWeight="bold">{loop.name}</Text>
            <Text fontSize="sm" color="gray.500">
              Created: {new Date(loop.created_at).toLocaleDateString()}
            </Text>
            <HStack mt={2} spacing={2}>
              {tools.map((tool, index) => (
                <Button key={index} size="xs" colorScheme="teal" variant="outline">
                  {tool}
                </Button>
              ))}
              {loop.tasks && loop.tasks.length > 3 && (
                <Text fontSize="xs" color="gray.500">+{loop.tasks.length - 3} more</Text>
              )}
            </HStack>
          </Box>
        </Flex>
        <Flex align="center" ml={4}>
          {!loop.approved ? (
            <HStack>
              <Icon as={FiEdit3} color="gray.500" boxSize={5} />
              <Text fontSize="sm" fontWeight="medium" color="gray.500">Draft</Text>
            </HStack>
          ) : !loop.built ? (
            <HStack>
              <Icon as={FiLoader} color="blue.500" boxSize={5} />
              <Text fontSize="sm" fontWeight="medium" color="blue.500">Building</Text>
            </HStack>
          ) : !loop.enabled ? (
            <HStack>
              <Icon as={FiPause} color="orange.500" boxSize={5} />
              <Text fontSize="sm" fontWeight="medium" color="orange.500">Paused</Text>
            </HStack>
          ) : (
            <HStack>
              <Icon as={FiCheckCircle} color="green.500" boxSize={5} />
              <Text fontSize="sm" fontWeight="medium" color="green.500">Deployed</Text>
            </HStack>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};
