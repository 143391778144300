import React from 'react';
import { VStack, Flex, Text, Button } from '@chakra-ui/react';

export const ToolSearchResults = ({ searchResults, addTool }) => (
  <VStack align="stretch" spacing={2} mb={6}>
    {searchResults.map((tool, index) => (
      <Flex key={index} justify="space-between" align="center" p={2} bg="gray.100" borderRadius="md">
        <Text>{tool}</Text>
        <Button size="sm" colorScheme="green" onClick={() => addTool(tool)}>Add</Button>
      </Flex>
    ))}
  </VStack>
);
