import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const SignInButton = ({ className }) => {
    const { loginWithRedirect } = useAuth0();

    const handleLogin = async () => {
        await loginWithRedirect({
            appState: {
                returnTo: "/dashboard",
            },
        });
    };

    return <button onClick={handleLogin} className={className}>Sign In</button>;
};

export default SignInButton;