import { useState, useEffect } from "react";

const useLocalStorage = (key, initialState) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      // console.log("Key: ", key, "Intial State: ", initialState, "Type of Stored Value: ", typeof initialState, "Stored Value: ", item)

      // If the value is a string, get it directly
      if (typeof initialState === "string") {
        // const item = window.localStorage.getItem(key);
        return item !== null ? item : initialState;
      } else {
        // const item = window.localStorage.getItem(key);
        return item !== null ? JSON.parse(item) : initialState;
      }
    } catch (error) {
      console.error("Error retrieving data from localStorage:", error);
      return initialState;
    }
  });

  const setValue = (value) => {
    console.log("Setting Value with Key: ", key, "Type of Value: ", typeof value, "Value: ", value)

    try {
      if (typeof value === "string") {
        // If the value is a string, store it directly
        setStoredValue(value);
        window.localStorage.setItem(key, value);
      } else {
        // If the value is not a string (e.g., an array), use JSON.stringify
        setStoredValue(value);
        window.localStorage.setItem(key, JSON.stringify(value));
      }

      // Dispatch a custom event with a key-specific name to notify other parts of the application
      const storageEvent = new CustomEvent(`storage_${key}`, {
        detail: { value },
      });
      window.dispatchEvent(storageEvent);
    } catch (error) {
      console.error("Error storing data in localStorage:", error);
    }
  };

  // useEffect(() => {
  //   const handleStorageChange = () => {
  //     try {
  //       console.log("Handling Storage Change with Key: ", key, "Type of Initial State: ", typeof initialState, "Initial State: ", initialState)

  //       const item = window.localStorage.getItem(key);
  //       // Parse JSON if item is not a string
  //       // setStoredValue(item !== null && typeof item === 'string' ? item : JSON.parse(item));
  //       // setStoredValue(item !== null && typeof item === 'string' ? JSON.parse(item) : item );
  //       setStoredValue(item !== null ? (typeof initialState === 'string' ? item : JSON.parse(item)) : initialState);

  //     } catch (error) {
  //       console.error('Error updating data from localStorage:', error);
  //     }
  //   };

  //   window.addEventListener('storage', handleStorageChange);

  //   return () => {
  //     window.removeEventListener('storage', handleStorageChange);
  //   };
  // }, [key, initialState]);

  return [storedValue, setValue];
};

// TO TEST:
// const useLocalStorage = (key, initialState) => {
//   const [storedValue, setStoredValue] = useState(() => {
//     try {
//       const item = window.localStorage.getItem(key);
//       // Try to parse the value, if it fails, return the item itself (string)
//       try {
//         return item !== null ? JSON.parse(item) : initialState;
//       } catch (error) {
//         return item !== null ? item : initialState;
//       }
//     } catch (error) {
//       console.error("Error retrieving data from localStorage:", error);
//       return initialState;
//     }
//   });

//   const setValue = (value) => {
//     try {
//       const valueToStore = value instanceof Function ? value(storedValue) : value;
//       setStoredValue(valueToStore);
//       window.localStorage.setItem(key, typeof valueToStore === "string" ? valueToStore : JSON.stringify(valueToStore));

//       // Dispatch a custom event with a key-specific name to notify other parts of the application
//       const storageEvent = new CustomEvent(`storage_${key}`, {
//         detail: { value: valueToStore },
//       });
//       window.dispatchEvent(storageEvent);
//     } catch (error) {
//       console.error("Error storing data in localStorage:", error);
//     }
//   };

//   return [storedValue, setValue];
// };

export default useLocalStorage;
