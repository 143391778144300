import React from 'react';
import {
  Box,
  Flex,
  Text,
  Heading,
  HStack,
  Select,
  useColorModeValue
} from '@chakra-ui/react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

export const LoopAnalytics = ({ dateRange, setDateRange, loopData, totalTriggers }) => (
  <Box
    bg={useColorModeValue('white', 'gray.700')}
    p={6}
    borderRadius="lg"
    boxShadow="sm"
    mb={8}
    border="1px"
    borderColor={useColorModeValue('gray.200', 'gray.600')}
  >
    <Flex justifyContent="space-between" alignItems="center" mb={4}>
      <Heading size="md" color="black">Loop Analytics</Heading>
      <HStack>
        <Text fontSize="sm">In the last</Text>
        <Select 
          size="sm" 
          value={dateRange} 
          onChange={(e) => setDateRange(Number(e.target.value))}
        >
          <option value={7}>7 days</option>
          <option value={30}>30 days</option>
          <option value={90}>90 days</option>
        </Select>
      </HStack>
    </Flex>
    <Text fontSize="sm" color="gray.500" mb={2}>
      Loop Usage
    </Text>
    <Text fontSize="2xl" fontWeight="bold" mb={4}>
      {totalTriggers} triggers
    </Text>
    <ResponsiveContainer width="100%" height={200}>
      <LineChart data={loopData}>
        <XAxis 
          dataKey="name" 
          axisLine={false} 
          tickLine={false}
          tick={{ fill: 'gray' }}
        />
        <YAxis hide={true} />
        <Tooltip 
          contentStyle={{ background: 'white', border: 'none', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}
          labelStyle={{ color: 'black' }}
        />
        <Line 
          type="monotone" 
          dataKey="value" 
          stroke="#48BB78" 
          strokeWidth={2} 
          dot={{ stroke: '#48BB78', strokeWidth: 2, r: 4 }} 
        />
      </LineChart>
    </ResponsiveContainer>
  </Box>
);
